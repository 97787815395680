.div-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.data-header-box {
    border: 1px solid #f2f2f2;
    border-radius: 20px;
    padding: 30px 15px;
}
.analysis-head{
    background: #FFF;
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,.08);
}

.a-h-h{
    margin-bottom: 20px;
}

.span-style{
    padding-left: 8px;
    margin-right: 13px;
    border-left: 2px solid #00A8F7;
    font-size: 14px;
    color: #4A4A4A;
    letter-spacing: 0;
    font-weight: 700;
    display: inline-block;
    height: 14px;
    line-height: 14px;
}

.span-style-text{
    font-size: 14px;
    color: #999;
    letter-spacing: 0;
}

.h-order-l{
    text-align: justify;
    text-justify: distribute-all-lines;
    text-align-last: justify;
    font-size: 0;
}

.span-style-yeji{
    margin: 0px;
    font-size: 14px;
    color: #333;
    padding: 0px;
    letter-spacing: 0;
}

.span-num{
    margin: 0px;
    align-content: center;
    font-size: 24px;
    color: #333;
}

.span-num-lastDay{
    margin: 0px;
    font-size: 8px;
    color: #002D00;
}

.m-b-20{
    margin-bottom: 20px!important;
}

.panel {
    border-radius: 15px;
    border: 1px solid #e7ecf3;
    border-bottom: 1px solid rgba(0, 0, 0, 0.17);
    margin-bottom: 15px;
}

.panel .panel-left {
    width: 62px;
    height: 100%;
    padding: 10px;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
}

.dash-div{
    margin-right: 30px;
    margin-left: 30px;
    border-radius:15px;
}

.panel .panel-right {
    width: 100%;
    padding: 10px;
}

/* .panel hr {
    border-color: rgba(0, 0, 0, 0.1)
}

.panel .panel-bg-cover {
    max-height: 180px;
    overflow: hidden
}

.panel .panel-bg-cover img {
    min-width: 100%;
    min-height: 100%;
    background-size: cover
} */

.bg-info {
    background-color: #00bcd4
}

.search-wrapper {
  display: flex !important;
  margin-bottom: 20px;
  align-items: center;
}

.search-wrapper .search-input {
  flex: 1 1;
}

.search-text {
  display: inline-block;
  margin-right: 10px;
  text-align: right;
}

@media screen and (max-width: 400px) {
  .search-text {
    display: block;
    text-align: left;
    margin-bottom: 5px;
  }
  .search-wrapper {
    display: block !important;
  }
}

.item-component-wrap {
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.common-table-style .ant-table table {
  border-collapse: collapse;
}

/* 适配表头固定，列固定，x，y轴滚动样式*/
.table-fixed-xy-scroll .ant-table .ant-table-scroll > .ant-table-body {
  overflow: scroll !important;
}

/* 可编辑表格 */
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.editable-row .ant-form-item-control {
  line-height: 37px;
}

.max-width {
  max-width: 160px;
}

.detail-inline-box {
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  justify-content: center;
  /* align-items: center; */
}
.select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
  background: #0086ff;
}

.select-selection{
  display: block;
  box-sizing: border-box;
  background-color: #0086ff;
}

.detail-inline-box .detail-inline-box-label {
  width: 30%;
  display: inline-block;
  text-align: right;
}

.detail-inline-box .detail-inline-box-value {
  width: 70%;
}

.shadow_div {
    width: 90%;
    height: 100%;
    border-radius: 3px;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 20px;
    border:#B4B4B4 1px solid;
    background:#fff;
    color:#333;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#909090,direction=120,strength=4);
    box-shadow:2px 2px 10px #909090;
}


.index-logo {
  height: 60px;
}

.index-header {
  background: #fff;
  padding: 0px;
}

.index-header-ul {
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
}

.index-header-li {
  cursor: pointer;
  width: 100px;
  text-align: center;
}
/*.menu-sider{*/
/*  overflow: auto;*/
/*  background-color: rgb(255, 255, 255);*/
/*  border-right: 1px solid rgb(234, 234, 234);*/
/*  flex: 0 0 270px;*/
/*  max-width: 270px;*/
/*  min-width: 270px;*/
/*  width: 270px;*/
/*}*/

.index-header-li:hover {
  background-color: #ccc;
}

.index-avatar-circle {
  border-radius: 50%;
  height: 30px;
}
.index-logo-div {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 200px;
  height: 64px;
  background-color: #001529;
}

.height-screen {
  height: 100vh;
}

.min-height-screen {
  min-height: 100vh !important;
}

.main-layout-container .img-circle {
  border-radius: 50%;
  margin-right: 5px;
  height: 29px;
}
.main-layout-container li.dropdown-user .dropdown-toggle > .username {
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
}
.main-layout-container .trigger {
  font-size: 20px;
  line-height: 64px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #14385b;
  padding: 0 20px;
}
.main-layout-container .trigger:hover {
  background: #14386b;
}
.main-layout-container .dcBtn {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
}
.main-layout-container .ant-layout-header {
  background: #002140;
}

.main-layout-container .ant-drawer-content {
  background: #002140;
}
.hideMenu {
  height: 0;
  overflow: hidden;
}

.min-height-screen {
  min-height: 100vh !important;
}

@charset "utf-8";

* {
    padding: 0;
    margin: 0;
}

body {
    font-size: 14px;
    background-color: #eff4f8;
    font-family: Microsoft YaHei, SimHei, Tahoma !important;
    /* background: url(../../image/login-bk.jpeg); */
}

.login-container {
    background-color: #102c58 !important;
    height: 100vh !important;
    background-size: 100% 100%;
    position: relative;
    background-image: url(/static/media/demo-1-bg.c64d8255.jpg);
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

#login-middle .login-header-title {
    height: 60px;
}

.login-botton {
    width: 980px;
    height: 100%;
    left: 50%;
    margin-left: -490px;
}

#login-middle .login-header-title h3 {
    font-size: 20px;
    text-align: center;
    padding: 30px 0 12px 0;
    font-weight: 100;
    color: #333;
}

#login-middle .login-header-title h4 {
    text-align: center;
    font-size: 18px;
    padding-top: 32px;
    color: #333;
    font-weight: 100;
}

#login-middle .header-login .login-form-hide {
    height: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    opacity: 0;
    z-index: -1;
    position: absolute;
}

#login-middle .header-login .login-form-hide form {
    height: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    opacity: 0;
    z-index: -1;
    position: fixed;
}

#login-middle .header-login .login-form-hide p {
    height: 0;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    opacity: 0;
    z-index: -1;
    position: absolute;
}

.netdisk-qrcode-container {
    height: 170px;
    padding-bottom: 49px;
    margin-top: 10px;
}

.hidden {
    display: none !important;
}

.header-container {
    position: absolute;
    height: 60px;
    width: 100%;
    left: 0;
    z-index: 3;
}

.all-index-banner {
    position: absolute;
    width: 100%;
    height: 100%;
}

.index-body-content {
    position: absolute;
    bottom: 80px;
    font-size: 44px;
    color: #fff;
}

.index-body-content p {
    position: relative;
    padding: 10px 100px;
}

.login-container .login-header {
    padding: 10px 0 0 12px;
}

#login-header {
    height: 60px;
    min-width: 1002px;
}

a:hover,
a:active {
    text-decoration: none;
    outline: 0;
}

.login-container .login-title,
.login-container .login-title a {
    margin: 30px 20px 0 0;
    color: #fff;
    text-decoration: none;
}

.login-container .login-title {
    float: right;
    margin: 25px 20px 0 0;
}

.login-container .login-title a:hover {
    text-decoration: underline;
}

.login-container .login-main {
    display: table;
    margin: 0 auto;
    clear: both;
    width: 90%;
    max-width: 375px;
    height: 90%;
    position: relative;
    overflow: hidden;
    top: 10%;
}

.login-container #login-middle {
    width: 352px;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.header-login {
    position: relative;
    width: 100%;
    min-height: 280px;
    z-index: 999;
    background: #fff;
    border-radius: 4px;
    vertical-align: middle;
    /* top: 50%;
      margin-top: -198px; */
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 50px;
}

.footer {
    text-align: center;
    font-size: 12px;
    color: #b6bac3;
    padding-bottom: 10px;
}

.footer .b-lnk-gy {
    color: #b6bac3;
    text-decoration: none;
}

.login-container .hide {
    display: none;
}

.login-tab .tab a:hover {
    background-color: #fafafa;
}

.login-tab .tab-selected a {
    font-weight: bold;
}

.tang-pass-login a,
.tang-pass-login label,
.tang-pass-login p,
.tang-pass-login li {
    font-size: 14px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.pass-foreignMobile-link-wrapper .pass-foreignMobile-link {
    color: #3582f8;
}

#login-middle .pass-login-tab {
    position: absolute;
    width: 115px;
    font-size: 14px;
    padding-left: 10px;
    bottom: 27px;
}

#login-middle .qcode-title,
#login-middle .account-title {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 14px;
    padding-left: 10px;
}

#login-middle .pass-login-tab a {
    text-decoration: none;
    font-size: 14px;
    color: #3582f8;
}

#login-middle .pass-login-tab a:hover {
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5;
}

.login-container .tang-pass-login {
    position: relative;
    padding: 0 20px 0 20px;
    /* // z-index: 50 */
}

.login-container .tang-pass-login a {
    text-decoration: none;
    margin: 0 4px;
    position: relative;
    top: 0;
    font-size: 14px;
    color: #3582f8;
}

.login-container .tang-pass-login a:hover {
    text-decoration: none;
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5;
}

.login-container .tang-pass-login-hide {
    position: absolute;
    top: -9999px;
}

.login-container .tang-pass-login .pass-generalErrorWrapper {
    height: auto;
    _height: 28px;
    min-height: 28px;
    color: #fc4343;
}

.login-container .tang-pass-login .pass-generalError {
    display: block;
    font-size: 12px;
    line-height: 14px;
    padding-top: 8px;
}

.login-container .tang-pass-login .pass-form-item {
    position: relative;
    margin-bottom: 0;
    clear: both;
    z-index: 18;
}

.login-container .pass-form .pass-form-item-password {
    border: 1px solid #e7e7e7;
    border-top-width: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.login-container .tang-pass-login .pass-form-item.pass-form-item-userName {
    border: 1px solid #eaeaea;
    z-index: 21;
    border-bottom-width: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.login-container .tang-pass-login .pass-form-item .pass-label-userName {
    background-position: 0 0;
}

.login-container .tang-pass-login .pass-form-item .pass-label-password {
    background-position: -16px 0;
}

.login-container .tang-pass-login .pass-form-item .pass-label-verifyCode {
    display: none;
}

input::-ms-clear {
    display: none;
}

input::-ms-reveal {
    display: none;
}

.login-container .tang-pass-login .pass-form-item .pass-text-input {
    position: relative;
    z-index: 17;
    display: block;
    padding: 7px 10px;
    height: 26px;
    width: 284px;
    border: 1px solid #dedede;
    font-size: 14px;
    color: #666;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    line-height: 26px;
    background-color: #fefefe;
    padding-left: 20px;
}

.login-container .tang-pass-login .pass-form-item .pass-text-input-verifyCode {
    height: 18px;
    margin-left: 0;
    line-height: normal;
    line-height: 18px \9;
    padding: 9px 10px;
}

.login-container .tang-pass-login .pass-form-item .pass-text-input-verifyCode {
    width: 76px;
    float: left;
}

.login-container .tang-pass-login .pass-form-item input.pass-text-input-userName {
    padding-left: 16px !important;
    border: 0;
    border-bottom: 1px solid #e7e7e7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.login-container .tang-pass-login .pass-form-item input.pass-text-input-password {
    padding-left: 16px;
    border: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.tang-pass-login .pass-form-item input.open {
    width: 164px;
    padding-right: 22px;
}

.login-container .tang-pass-login .pass-form-item .pass-item-selectbtn-userName {
    position: absolute;
    display: block;
    height: 16px;
    width: 16px;
    /* background: url(../images/pass_login_icons.png) no-repeat 0 -30px; */
    top: 6px;
    right: 31px;
    cursor: pointer;
}

.login-container .tang-pass-login .pass-form-item .pass-item-selectbtn-userName:hover {
    background-position: 0 -46px;
}

.login-container .tang-pass-login .pass-form-item span.open {
    background-position: -18px -30px;
}

.login-container .tang-pass-login .pass-form-item span.open:hover {
    background-position: -18px -46px;
}

.login-container .tang-pass-login .pass-verifyCode {
    width: 90px;
    height: 36px;
    /* background: url(../images/loading_16.gif) no-repeat center center; */
    border: 1px solid #ddd;
    float: left;
    margin-left: 5px;
}

.login-container .tang-pass-login .pass-change-verifyCode {
    padding-left: 5px;
    font-size: 12px;
    text-decoration: none;
    line-height: 38px;
    float: left;
    cursor: pointer;
}

.login-container .tang-pass-login .pass-form-item .pass-placeholder {
    position: absolute;
    z-index: 1999;
    display: block;
    top: 1px;
    left: 0;
    height: 28px;
    line-height: 40px;
    width: 140px;
    padding-left: 13px;
    font-size: 14px;
    color: #ccc;
    cursor: text;
}

.login-container .tang-pass-login .pass-form-item .pass-placeholder-foreignMobile {
    padding-left: 87px;
}

.login-container .tang-pass-login .pass-form-item .pass-placeholder-verifyCode {
    width: 50px;
    line-height: 38px;
    *line-height: 40px;
    left: 11px;
    padding-left: 0;
}

.login-container .tang-pass-login .pass-form-item .pass-clearbtn {
    top: 12px;
}

.tang-pass-login .pass-form-item span.pass-clearbtn-verifyCode {
    right: inherit;
    left: 75px;
    top: 12px;
    z-index: 1999;
}

.tang-pass-login .pass-form-item span.pass-clearbtn-smsVerifyCode {
    right: inherit;
    left: 90px;
}

.tang-pass-login .pass-form-item .pass-clearbtn:hover {
    background-position: 0 -16px;
}

.tang-pass-login .pass-form-item-memberPass {
    margin-bottom: 25px;
    padding-top: 19px;
    *padding-top: 10px;
}

.tang-pass-login .pass-form-item-memberPass input {
    vertical-align: middle;
}

.tang-pass-login .pass-form-item-memberPass label {
    color: #666;
    margin-left: 5px;
    font-size: 14px;
}

.tang-pass-login .pass-form-item-submit {
    position: relative;
    padding-top: 20px;
}

.tang-pass-login .pass-form-item-submit input {
    width: 278px;
    height: 41px;
    background: #2592e0;
    border-radius: 3px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.tang-pass-login .pass-form-item-submit input:hover {
    background: #42a7f0;
}

.tang-pass-login .pass-form-item-submit input:focus {
    background: #1a79bd;
}

.login-container .tang-pass-login .pass-form-item-submit .pass-button-submit {
    width: 100%;
    height: 41px;
    background: #3582f8;
    border-radius: 4px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    font-size: 16px;
    border: 0;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-family: Microsoft YaHei, SimHei, Tahoma;
    font-weight: 100;
}

.login-container .tang-pass-login .pass-form-item-submit .pass-button-submit:hover {
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
}

.login-container .tang-pass-login .pass-form-item-memberPass input {
    vertical-align: middle;
    margin-top: -4px;
}

.tang-pass-login .pass-form-item-submit .pass-reglink {
    position: absolute;
    top: 40px;
    right: 0;
}

.login-container .tang-pass-login .pass-form-item-submit .pass-fgtpwd {
    color: #666;
    text-decoration: none;
    font-size: 14px;
    position: relative;
    top: 14px;
    margin: 0;
}

#pass-phoenix-login {
    height: 65px;
    margin-top: 34px;
    background: #f3f8ff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

#pass-phoenix-login .pass-phoenix-title {
    display: none;
}

#pass-phoenix-login .pass-phoenix-list {
    float: left;
    line-height: 17px;
    zoom: 1;
    padding-left: 106px;
}

#pass-phoenix-login .pass-phoenix-list:after {
    line-height: 20px;
    content: '\20';
    display: block;
    height: 0;
    clear: both;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-list {
    float: left;
}

.pass-sms-btn,
.pass-unamelogin-btn,
.pass-emaillogin-btn {
    display: inline-block;
    margin-right: 15px;
    color: #00c;
    text-decoration: underline;
    padding-left: 18px;
    cursor: pointer;
    font-size: 12px;
    /* background: url(../../../image/pass_login_icons.png) no-repeat -181px -64px; */
    width: 0;
    height: 18px;
    overflow: hidden;
    float: left;
}

.pass-qrcode-btn {
    position: absolute;
    top: 0;
    width: 172px;
    left: 0;
    height: 58px;
    text-indent: -9999em;
    border-bottom: 3px solid #edf4ff;
}

.pass-normal-btn {
    position: absolute;
    top: 0;
    width: 172px;
    right: 0;
    height: 58px;
    text-indent: -9999em;
    border-bottom: 3px solid #edf4ff;
}

.pass-unamelogin-btn {
    background-position: -217px -64px;
}

.pass-sms-btn {
    background-position: -108px -64px;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina {
    background-position: -62px -0;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    background: #bad4fd;
    left: 120px;
    border-left: 1px solid #bad4fd;
    margin-top: 7px;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    background: #bad4fd;
    left: 182px;
    border-left: 1px solid #bad4fd;
    margin-top: -10px;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina {
    background-position: -62px -0;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina:hover {
    background-position: -37px -0;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-qzone {
    width: 18px;
    background-position: -18px -0;
    height: 21px;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-qzone:hover {
    width: 18px;
    height: 21px;
    background-position: 0 0;
}

#pass-phoenix-login .pass-phoenix-list li {
    display: inline-block;
    margin: 0 16px;
    width: 25px;
    height: 22px;
    float: left;
    color: #00c;
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    list-style: none;
    text-indent: -9999em;
    overflow: hidden;
    margin-top: 28px;
    background-size: cover;
}

#pass-phoenix-list-login li {
    /* background: url(../images/17third.png) no-repeat */
}

.choiceuser-article {
    padding: 0 20px;
}

.choiceuser-article .choiceuser-msg {
    margin-top: 10px;
    font-size: 12px;
    color: #666;
    line-height: 24px;
}

.choiceuser-article .choiceuser-back {
    margin-top: 10px;
    clear: both;
}

.choiceuser-article .choiceuser-back a {
    display: block;
    text-align: right;
    color: #1b66c7;
    text-decoration: none;
}

.choiceuser-article .choiceuser-btn:after {
    content: '\20';
    display: block;
    height: 0;
    clear: both;
}

.choiceuser-article .choiceuser-btn {
    padding-left: 20px;
    zoom: 1;
}

.choiceuser-article .choiceuser-btn input {
    display: block;
    float: left;
    margin-right: 15px;
    width: 80px;
    height: 32px;
    border-radius: 4px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 0;
    margin: 10px 10px 0 0;
    cursor: pointer;
    color: #666;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    font-weight: bold;
}

.choiceuser-article .choiceuser-btn input:hover {
    background-color: #eee;
    border-color: #dadada;
}

.login-container .tang-pass-qrcode .tang-pass-qrcode-title {
    display: none;
    height: 30px;
    line-height: 30px;
    color: #666;
    font-weight: bold;
}

.login-container .tang-pass-qrcode .tang-pass-qrcode-content {
    text-align: center;
    margin: 22px 0 0 0;
    left: 0;
}

.login-container .tang-pass-qrcode .tang-pass-qrcode-content img {
    width: 160px;
    height: 160px;
    margin: 2px 60px;
    /* background: url(../../../image/loading.jpg) no-repeat center; */
    background-size: contain;
    border: 1px solid #e6e6e6;
    padding: 10px;
}

.login-container .tang-pass-qrcode .pass-qrcode-scanSuccessWrapper {
    /* background: url(../../../image/right.png) center 30px no-repeat #fff; */
    min-height: 200px;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
    width: 100%;
    padding-top: 5px;
    background-size: 20%;
}

.login-container .wallet-qrcode-scanSuccess {
    color: #333;
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
}

.login-container .wallet-qrcode-icon {
    background: 0;
}

.login-container .tang-pass-login-phoenix .pass-phoenix-list .phoenix-btn-item {
    background: 0;
}

.login-container .tang-pass-login-phoenix {
    border: 0;
    overflow: hidden;
}

.login-container .tang-pass-qrcode .pass-qrcode-scanSuccessWrapper p {
    font-size: 14px;
    color: #999;
    margin-top: 10px;
}

.login-container .tang-pass-qrcode .tang-pass-qrcode-info {
    line-height: 16px;
    color: #333;
    font-weight: bold;
    padding-top: 20px;
}

.login-container .tang-pass-qrcode .pass-qrcode-scanSuccess {
    font-size: 20px;
    margin: 10px 0;
    color: #333;
    font-weight: bold;
}

.login-container .tang-pass-qrcode .pass-qrcode-link-back {
    display: block;
    text-align: right;
    text-decoration: none;
    color: #1b66c7;
    cursor: pointer;
}

.login-container .tang-pass-qrcode .pass-qrcode-link-back:hover {
    text-decoration: underline;
}

.tang-pass-sms {
    display: none;
}

.tang-pass-sms .tang-pass-sms-title {
    height: 30px;
    line-height: 30px;
    color: #666;
    font-weight: bold;
}

.tang-pass-sms .tang-pass-sms-tip {
    line-height: 18px;
    color: #999;
    font-weight: 100;
}

.tang-pass-sms p.pass-form-item-smsVerifyCode:after,
.tang-pass-login p.pass-form-item-verifyCode:after {
    line-height: 20px;
    content: '\20';
    display: block;
    height: 0;
    clear: both;
}

.tang-pass-sms p.pass-form-item-smsVerifyCode,
.tang-pass-login p.pass-form-item-verifyCode {
    position: relative;
    height: 38px;
    margin-top: 10px;
    zoom: 1;
}

.tang-pass-sms .pass-form-item .pass-sms-link-back {
    display: block;
    text-align: right;
    padding-top: 10px;
}

.tang-pass-sms .pass-form-item input.pass-text-input-smsPhone {
    padding-left: 20px;
    /* background: url(../../../image/input_icons_16.png) no-repeat 4px -38px */
}

.tang-pass-sms .pass-form-item input.pass-text-input-smsVerifyCode {
    width: 90px;
    float: left;
    margin-right: 10px;
}

.tang-pass-sms .pass-form-item span.pass-placeholder-smsPhone {
    padding-left: 22px;
}

.tang-pass-sms .pass-form-item span.pass-placeholder-smsVerifyCode {
    width: 70px;
}

.tang-pass-sms .pass-form-item span.pass-item-timer,
.tang-pass-sms .pass-form-item span.pass-item-time-timing {
    width: 84px;
}

.tang-pass-login .pass-item-timer {
    display: block;
    float: left;
    height: 28px;
    width: 84px;
    font-size: 12px;
    color: #666;
    border: 1px solid #ddd;
    background-color: #f7f7f7;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.tang-pass-login .pass-item-timer:hover {
    background-color: #eee;
    border-color: #dadada;
}

.tang-pass-login .pass-item-time-timing {
    display: block;
    float: left;
    height: 28px;
    width: 84px;
    font-size: 12px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    color: #999;
    border: 1px solid #ebebeb;
    background-color: #f6f6f6;
    cursor: default;
}

.tang-pass-login .pass-suggestion-list {
    position: absolute;
    margin: 0;
    padding: 0;
    z-index: 21;
    top: 28px;
    left: 0;
    border: 1px solid #dedede;
    border-top: 0;
    background: #fff;
    width: 206px;
}

.tang-pass-login li.pass-item-suggsetion {
    position: relative;
    z-index: 2001;
    line-height: 14px;
    padding: 5px 0 5px 6px;
    color: #888;
    font-family: '宋体';
    font-size: 12px;
    color: #333;
    cursor: pointer;
    word-spacing: break-word;
    word-break: break-all;
}

.tang-pass-login li.pass-item-suggsetion a {
    position: absolute;
    display: none;
    top: 4px;
    right: 4px;
    height: 16px;
    width: 16px;
    /* background: url(../../../image/pass_login_icons.png) no-repeat 0 0 */
}

.tang-pass-login li.pass-item-suggsetion_hover a {
    display: block;
}

.tang-pass-login li.pass-item-suggsetion a:hover {
    background-position: 0 -16px;
}

.tang-pass-login .pass-item-suggsetion_hover {
    background-color: #f7f7f7;
}

.pass-login-title {
    clear: both;
    height: 35px;
    padding: 0 0 0 20px;
    font-size: 14px;
    color: #2486c4;
    font-weight: bold;
    line-height: 50px;
}

.pass-login-title .bd-logo {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 21px;
    width: 28px;
    /* background: url(../../../image/pan_login_logo.png) no-repeat; */
    position: relative;
    top: 3px;
}

.netdisk_pass_loading_tips {
    text-align: center;
    padding-top: 110px;
}

#login-middle .tang-pass-wltopc-qrcode-init {
    float: none;
}

#login-middle .tang-pass-login .pass-foreignMobile-link-wrapper,
#login-middle .tang-pass-login .pass-foreignMobile-back-wrapper {
    position: absolute;
    right: 20px;
    margin-top: -6px;
    font-size: 14px;
}

#login-middle .tang-pass-login .pass-foreignMobile-back-wrapper {
    display: none;
}

#login-middle .tang-pass-login .pass-foreignMobile-msg {
    font-weight: 100;
    color: #666;
    margin-bottom: 10px;
    font-size: 14px;
}

#login-middle .tang-pass-login .pass-foreignMobile-msg span {
    font-weight: 100;
    color: #999;
    padding-left: 6px;
}

#login-middle .tang-pass-login .pass-form-item-PhoneCountry {
    z-index: 26;
}

#login-middle .tang-pass-login .pass-form-item-PhoneCountry {
    z-index: 26;
}

#login-middle .tang-pass-login .pass-form-item-PhoneCountry .pass-label,
#login-middle .tang-pass-login .pass-form-item .pass-label {
    position: absolute;
    font-size: 12px;
    color: #666;
    padding-bottom: 10px;
    text-indent: -999em;
    height: 10px;
    width: 17px;
    top: 11px;
    z-index: 999;
    left: 11px;
    display: block;
    background: 0;
}

#login-middle .tang-pass-login .pass-form-item .pass-label-userName {
    display: none;
}

#login-middle .tang-pass-login .pass-form-item-PhoneCountry .pass-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 66px;
    height: 41px;
    padding: 0;
    line-height: 41px;
    border-right: 1px solid #ddd;
    cursor: pointer;
    text-indent: 0;
    overflow: hidden;
    z-index: 20;
    font-size: 14px;
    text-align: center;
    background: 0;
}

.tang-pass-login .pass-form-item-PhoneCountry .pass-label-code-up {
    background-position: 62px -39px;
}

.tang-pass-login .pass-form-item-PhoneCountry .pass-country-list {
    position: absolute;
    top: 42px;
    left: 0;
    border: 1px solid #dedede;
    border-top: 0;
    background: #fff;
    width: 310px;
    height: 170px;
    overflow-y: auto;
    display: none;
    transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -webkit-transition: 0.3s;
}

.login-container .tang-pass-login .pass-form-item-PhoneCountry input.pass-text-input {
    width: 213px;
    padding-left: 87px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.tang-pass-login li.pass-item-country {
    position: relative;
    line-height: 14px;
    padding: 10px 0 10px 0;
    color: #888;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    word-break: break-all;
}

.tang-pass-login li.pass-item-country-hover {
    background: #f7f7f7;
}

.tang-pass-login li.pass-item-country span {
    display: inline-block;
    width: 74px;
    padding-left: 12px;
}

input:focus {
    outline: 0;
}

.login-info .link-create {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 56px;
    padding: 6px 10px;
    border: 1px solid #bad4fd;
    color: #3582f8;
    text-decoration: none;
    border-radius: 4px;
    text-align: center;
}

.login-info a:hover {
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5;
}

.canvas-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    opacity: 0.8;
    top: 0;
    z-index: 1001;
}

.qrcode-dialog {
    position: absolute;
    top: 50%;
    z-index: 1003;
    width: 370px;
    height: 380px;
    background: #fff;
    left: 50%;
    margin-left: -178px;
    margin-top: -170px;
    border-radius: 4px;
}

.dialog-title {
    color: #424e67;
    padding: 18px 20px;
    font-size: 16px;
    border-bottom: 1px solid #ececec;
}

.dialog-title .close {
    /* background: url(../../../image/close.png) top no-repeat; */
    width: 13px;
    height: 14px;
    position: absolute;
    right: 0;
    margin: 20px;
    top: 0;
}

.dialog-content {
    display: block;
    border: 1px solid #ececec;
    margin: 24px auto 15px auto;
}

.addon-content {
    font-size: 14px;
    color: #424e67;
    text-align: center;
    padding: 0 0 10px 0;
}

.dialog-footer {
    margin-top: 20px;
}

.dialog-button {
    width: 256px;
    background: #3582f8;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    padding: 12px 0;
    font-size: 16px;
    border-radius: 4px;
    margin-top: 17px;
}

.dialog-button a {
    color: #fff;
    text-decoration: none;
}

.tang-pass-qrcode-img {
    transition: margin-left 0.4s linear;
    -moz-transition: margin-left 0.4s linear;
    -webkit-transition: margin-left 0.4s linear;
    -o-transition: margin-left 0.4s linear;
}

.login-container #login-middle .guide-img {
    /* background: url(../../../image/baidu.png) no-repeat; */
    height: 182px;
    width: 133px;
    position: absolute;
    right: 15px;
    top: 112px;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    opacity: 0;
}

.login-container .tang-pass-qrcode .tang-pass-qrcode-content .animate-left {
    margin-left: -70px;
}

.login-container #login-middle .animate-show {
    transition: opacity 2s;
    -moz-transition: opacity 2s;
    -webkit-transition: opacity 2s;
    -o-transition: opacity 2s;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
}

.animated {
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
}

.qrcode-addoninfo {
    text-align: center;
    color: #333;
    position: relative;
    z-index: 49;
    font-size: 14px;
}

.qrcode-addoninfo a {
    color: #3582f8;
    text-decoration: none;
}

.qrcode-addoninfo a:hover {
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    opacity: 0.5;
}

.login-container .active {
    display: none;
}

.login-container .ibg-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background-size: cover;
    -webkit-transition: background 1s;
    transition: background 1s;
}

@media screen and (min-width: 1400px) {
    .login-container {
        height: 720px;
        min-width: 1200px;
    }

    #login-download {
        margin-top: 60px;
    }
}

@media screen\9 {
    .login-container #login-middle {
        width: 352px;
        position: absolute;
        top: 0;
        height: 100%;
        right: 10px;
    }

    .header-login {
        top: 20px;
        margin-top: 0;
    }

    .netdisk-qrcode-container {
        height: 247px;
        padding-bottom: 0;
    }

    #pass-phoenix-login {
        height: 62px;
        margin-top: 31px;
    }

    #netdisk_qrcode_login_form {
        height: 210px;
    }

    #pass-phoenix-login .pass-phoenix-list li {
        margin: 0 8px;
    }

    #pass-phoenix-login .pass-phoenix-list .bd-acc-tsina {
        width: 65px;
        margin-top: 34px;
        /* background: url(../../../image/weibo.png) no-repeat */
    }

    #pass-phoenix-login .pass-phoenix-list .bd-acc-tsina:hover {
        background-position: 0 0;
        -moz-opacity: 0.5;
        filter: alpha(opacity=50);
        opacity: 0.5;
    }

    #pass-phoenix-login .pass-phoenix-list .bd-acc-qzone {
        margin-top: 31px;
        /* background: url(../../../image/qq.png) no-repeat; */
        margin-left: 6px;
    }

    #pass-phoenix-login .pass-phoenix-list .bd-acc-qzone:hover {
        background-position: 0 0;
        -moz-opacity: 0.5;
        filter: alpha(opacity=50);
        opacity: 0.5;
    }
}

#pass-phoenix-login .pass-phoenix-list li {
    margin: 0 8px \0;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina:before {
    background: #fff \0;
    border-left: 1px solid #fff \0;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina:after {
    background: #fff \0;
    border-left: 1px solid #fff \0;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina {
    width: 65px \0;
    margin-top: 29px \0;
    /* background: url(../../../image/weibo.png) no-repeat\0 */
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-tsina:hover {
    background-position: 0 0 \0;
    -moz-opacity: 0.5 \0;
    filter: alpha(opacity=50) \0;
    opacity: 0.5 \0;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-qzone {
    margin-top: 28px \0;
    /* background: url(../images/qq.png) no-repeat\0; */
    margin-left: 6px \0;
}

#pass-phoenix-login .pass-phoenix-list .bd-acc-qzone:hover {
    background-position: 0 0 \0;
    -moz-opacity: 0.5 \0;
    filter: alpha(opacity=50) \0;
    opacity: 0.5 \0;
}

@media screen and (min-width: 4001px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 4000px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 2999px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 2549px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 2200px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1920px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

/* css 注释说明：设置了浏览器宽度不小于1201px时 head_Top 显示280px高度 */
@media screen and (max-width: 1200px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

/* 设置了浏览器宽度不大于1200px时 head_Top 显示150px高度 */
@media screen and (max-width: 900px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

/* 设置了浏览器宽度不大于900px时 head_Top 显示200px高度 */
@media screen and (max-width: 500px) {
  .head_Top {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 4001px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

@media screen and (max-width: 4000px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

@media screen and (max-width: 2999px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

@media screen and (max-width: 2549px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

@media screen and (max-width: 2200px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

@media screen and (max-width: 1920px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

/* css 注释说明：设置了浏览器宽度不小于1201px时 head_Top 显示280px高度 */
@media screen and (max-width: 1200px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

/* 设置了浏览器宽度不大于1200px时 head_Top 显示150px高度 */
@media screen and (max-width: 900px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

/* 设置了浏览器宽度不大于900px时 head_Top 显示200px高度 */
@media screen and (max-width: 500px) {
  .head_TopIn {
    width: 40%;
    height: 100%;
    margin-left: 30%;
  }
}

.head_Top {
  width: 100%;
  /* height: calc(100% - 24px); */
  float: right;
  height: 100%;
  padding-bottom: 30px;
  background: #f6f6f6;
}

.select-selection--single {
  position: relative;
  height: 32px;
  cursor: pointer;
}

.select-selection{
  display: block;
  box-sizing: border-box;
  background-color: #0086ff;
}

.head_TopIn {
  padding: 10px;
  width: 100%;
  height: 100%;
  margin-left: 1%;
  margin-right: 1%;
  margin-top: 5%;
  float: left;
  background-color: #ffffff;
}

.shadow_div {
  width: 97%;
  height: 100%;
  border-radius: 3px;
  margin-top: 8px;
  margin-left: 5px;
  align: center;
  margin-right: 0px;
  border: #b4b4b4 1px solid;
  background: #fff;
  color: #333;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#909090, direction=120, strength=4);
  box-shadow: 2px 2px 10px #909090;
}

.head_Bg {
  width: 100%;
  /* height: calc(100% - 24px); */
  float: right;
  background: #f6f6f6;
}

.head_LeftContainer {
  width: 20%;
  height: 40%;
  background-color: #282c34;
  float: left;
  margin-top: 3%;
}
.span_insure {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.head_RightContainer {
  margin-top: 2%;
  width: 40%;
  height: 50%;
  float: right;
  background-color: #61dafb;
  opacity: 0.5;
}

.head_RightContainer .head_Item {
  width: 15%;
  height: 30%;
  margin-right: 8%;
  background-color: #282c34;
  float: left;
  overflow: hidden;
}

.head_Input {
  width: 60%;
  height: 30%;
  background-color: darkred;
  margin-top: 5%;
  margin-left: 5%;
}

.head_RightTwoContainer {
  width: 15%;
  height: 16%;
  background-color: #999999;
  float: right;
  margin-top: 1.2%;
  margin-right: 3%;
}

.head_RightTwoContainer_Item1 {
  width: 40%;
  height: 100%;
  background-color: #61dafb;
  float: left;
  margin-right: 8%;
}

.head_RightTwoContainer_Item2 {
  width: 40%;
  height: 100%;
  background-color: #61dafb;
  float: left;
  margin-right: 8%;
}

.payDiv {
  width: 600px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px;
  border: 1px solid #eaeaea;
  margin-top: 20px;
}

.my-wrap .has-error .ant-form-explain {
  color: green;
}

.priceDiv {
  width: 80%;
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px;
  border: 1px solid #eaeaea;
  margin-top: 20px;
}

.programmepayBox {
  padding: 5px;
  border: 1px solid #eaeaea;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #fff;
  font-size: 12px;
  color: #000;
  text-align: center;
  cursor: pointer;
}

.programme_payBox {
  padding: 4px;
  display: inline-block;
  border: 1px solid #eaeaea;
  /*borderRadius:3px;*/
  border-radius: 3px;
  background-color: #fff;
  font-size: 18px;
  color: #000;
  text-align: center;
  margin-bottom: 10px;
  line-height: 30px;
  cursor: pointer;
}

.payBox {
  padding: 0px 10px;
  /* display: inline-block; */
  /*border: 1px solid #eaeaea;*/
  /*background-color: #fff;*/
  /* font-size: 18px; */
  color: #000;
  text-align: center;
  height: 60px;
  /*width: 120px;*/
  vertical-align: middle;
  text-align: center;
  align-content: center;
  align-items: center;
  margin-bottom: 10px;
  line-height: 60px;
  cursor: pointer;
}

.media-img-style img {
  max-width: 100%;
}
.ant-anchor-link {
  width: auto;
  float: left;
  padding: 24px 16px 8px 16px !important;
  margin-bottom: 16px;
  text-align: center;
}
.ant-anchor-link.ant-anchor-link-active {
  border-bottom: 2px solid #1890ff;
}
.ant-anchor-ink {
  display: none;
}
.sp-style .ant-card-body {
  padding: 24px 3px;
}
.ant-affix {
  overflow-x: auto;
}

.div-img {
  background-size:100% auto;
  margin-bottom: 10px;
  /*width: 100%;*/
  /*height: 500px;*/
  background: url(/static/media/forte.d67fc748.png);
  background-repeat:no-repeat;
  /*background-size: cover;*/
}

/*basic*/
.font-link {
  cursor: pointer;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-algin-right {
  text-align: right;
}

.text-algin-left {
  text-align: left;
}

.text-algin-center {
  text-align: center;
}

/*marginBottom*/
.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

/*marginLeft*/
.margin-left-5 {
  margin-left: 5px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-16 {
  margin-left: 16px;
}

/*marginTop*/
.margin-top-5 {
  margin-top: 5px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-48 {
  margin-top: 48px;
}

/*marginRight*/
.margin-right-5 {
  margin-right: 5px;
}

.margin-right-8 {
  margin-right: 8px;
}

/*padding*/
.padding-16 {
  padding: 16px;
}
/*paddingTop*/
.padding-top-8 {
  padding-top: 8px;
}
.padding-top-16 {
  padding-top: 16px;
}
.padding-top-32 {
  padding-top: 32px;
}
/*paddingLeft*/
.padding-left-8 {
  padding-left: 8px;
}

/*paddingBottom*/

/*width*/
.width-percent-100 {
  width: 100%;
}

.width-percent-50 {
  width: 50%;
}

.display-flex {
  display: inline-flex;
  width: 100%;
}

/*height*/
.height-32 {
  height: 32px;
}

/*fontSize*/
.font-size-12 {
  font-size: 12px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-40 {
  font-size: 40px;
}

/*font-color*/
.font-color-white {
  color: white;
}

.font-color-red {
  color: red;
}

.font-color-green {
  color: green;
}

.font-color-blue {
  color: #1890ff;
}

.font-color-black {
  color: black;
}

.font-color-orange {
  color: orange;
}

.font-color-gray {
  color: gray;
}

.font-color-purple {
  color: purple;
}

.font-color-yellow {
  color: yellow;
}

.a-style {
  cursor: pointer;
  color: #1890ff;
}

.words-auto {
  overflow: hidden;
  /*超过部分不显示*/
  text-overflow: ellipsis;
  /*超过部分用点点表示*/
  white-space: nowrap;
  /*不换行*/
}

.hide {
  display: none;
}

/*backgroundColor*/

/*border-radius*/
.border-radius-14 {
  border-radius: 14px;
}

/*font*/
.font-bold {
  font-weight: bold;
  color: #000;
}

.font-link {
  cursor: pointer;
  color: blue;
}

.title-div {
  display: inline-block;
  width: 90px;
  text-align: right;
}
.title-div-left {
  display: inline-block;
  width: 90px;
  text-align: left;
}
.tab-style {
  display: inline-block;
  border-radius: 25px;
  text-align: center;
  padding: 2px 16px;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 3px;
}
.tab-style.active {
  background-color: #1890ff;
  color: #fff;
}
.payBtn {
  border: 1px solid #eaeaea;
  margin-right: 20px;
  height: 45px;
  padding: 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.payBtn.active {
  border: 2px solid #ff6701;
}
.payBtn .WeRecommend {
  display: none;
  position: absolute;
  width: 40px;
  left: -20px;
  top: -12px;
}
.payBtn.active .WeRecommend {
  display: inline-block;
}
.hide-columns {
  display: none;
}

