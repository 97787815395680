@import '~antd/dist/antd.css';

/*basic*/
.font-link {
  cursor: pointer;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-algin-right {
  text-align: right;
}

.text-algin-left {
  text-align: left;
}

.text-algin-center {
  text-align: center;
}

/*marginBottom*/
.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

/*marginLeft*/
.margin-left-5 {
  margin-left: 5px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-16 {
  margin-left: 16px;
}

/*marginTop*/
.margin-top-5 {
  margin-top: 5px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-48 {
  margin-top: 48px;
}

/*marginRight*/
.margin-right-5 {
  margin-right: 5px;
}

.margin-right-8 {
  margin-right: 8px;
}

/*padding*/
.padding-16 {
  padding: 16px;
}
/*paddingTop*/
.padding-top-8 {
  padding-top: 8px;
}
.padding-top-16 {
  padding-top: 16px;
}
.padding-top-32 {
  padding-top: 32px;
}
/*paddingLeft*/
.padding-left-8 {
  padding-left: 8px;
}

/*paddingBottom*/

/*width*/
.width-percent-100 {
  width: 100%;
}

.width-percent-50 {
  width: 50%;
}

.display-flex {
  display: inline-flex;
  width: 100%;
}

/*height*/
.height-32 {
  height: 32px;
}

/*fontSize*/
.font-size-12 {
  font-size: 12px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-40 {
  font-size: 40px;
}

/*font-color*/
.font-color-white {
  color: white;
}

.font-color-red {
  color: red;
}

.font-color-green {
  color: green;
}

.font-color-blue {
  color: #1890ff;
}

.font-color-black {
  color: black;
}

.font-color-orange {
  color: orange;
}

.font-color-gray {
  color: gray;
}

.font-color-purple {
  color: purple;
}

.font-color-yellow {
  color: yellow;
}

.a-style {
  cursor: pointer;
  color: #1890ff;
}

.words-auto {
  overflow: hidden;
  /*超过部分不显示*/
  text-overflow: ellipsis;
  /*超过部分用点点表示*/
  white-space: nowrap;
  /*不换行*/
}

.hide {
  display: none;
}

/*backgroundColor*/

/*border-radius*/
.border-radius-14 {
  border-radius: 14px;
}

/*font*/
.font-bold {
  font-weight: bold;
  color: #000;
}

.font-link {
  cursor: pointer;
  color: blue;
}

.title-div {
  display: inline-block;
  width: 90px;
  text-align: right;
}
.title-div-left {
  display: inline-block;
  width: 90px;
  text-align: left;
}
.tab-style {
  display: inline-block;
  border-radius: 25px;
  text-align: center;
  padding: 2px 16px;
  background-color: #fff;
  cursor: pointer;
  margin-bottom: 3px;
}
.tab-style.active {
  background-color: #1890ff;
  color: #fff;
}
.payBtn {
  border: 1px solid #eaeaea;
  margin-right: 20px;
  height: 45px;
  padding: 3px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.payBtn.active {
  border: 2px solid #ff6701;
}
.payBtn .WeRecommend {
  display: none;
  position: absolute;
  width: 40px;
  left: -20px;
  top: -12px;
}
.payBtn.active .WeRecommend {
  display: inline-block;
}
.hide-columns {
  display: none;
}
